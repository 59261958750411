import React, { useState, useEffect, useRef } from 'react';

const InputMask = (props) => {
    const inputCard = useRef();

    const handleChange = () => {
        const cardValue = inputCard.current.value
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        // konsole.log(cardValue, cardValue[2]);
        inputCard.current.value = !cardValue[2]
            ? cardValue[1]
            : `(${cardValue[1]}) ${cardValue[2]}${`${cardValue[3] ? `-${cardValue[3]}` : ''
            }`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`;
        const numbers = inputCard.current.value.replace(/(\D)/g, '');
        props.onValueChange(numbers)
        props.setValue("phoneNumber",numbers);
    props.setClearError('phoneNumber');
    };

    useEffect(() => {
        handleChange();
    }, []);

    return (
        <>
            <input style={{marginBottom:"5px"}}
            type="text"
            className='form-control bg-light fs-6'
            placeholder='Enter your cell number'
            id="phoneNumber"
             ref={inputCard} 
             onChange={handleChange} />
        </>
    );
};

export default InputMask;
