import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function CollapsibleExample(props) {
    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" style={{ backgroundColor: "#4C4744", text: "center" }}>
            {
                (parseInt(props.subtenantId) !== 26)?
                <Container>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="m-auto d-flex justify-content-around w-75 text-light">
                            <Nav.Link href="#"></Nav.Link>
                            <Nav.Link href="#"></Nav.Link>
                            <Nav.Link href="#"></Nav.Link>
                            <Nav.Link href="#"></Nav.Link>
                            <Nav.Link href="#"></Nav.Link>
                            <Nav.Link href="#"></Nav.Link>
                            {/* <NavDropdown > */}
                {/* <NavDropdown.Item ></NavDropdown.Item> */}
                {/* <NavDropdown.Item href="#action/3.2">
                    Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                    Separated link
                </NavDropdown.Item> */}
                {/* </NavDropdown> */}
                        </Nav>

                    </Navbar.Collapse>
                </Container>:
                <></>
            }
        </Navbar>
    );
}

export default CollapsibleExample;