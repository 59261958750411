import konsole from "../network/konsole";
import moment from "moment";

const commonLib = {
    getQueryParameters : function(urlQuery,name){
        return new URLSearchParams(urlQuery).get(name);
    },
    setSessionStoarge: function(key,value){
        sessionStorage.setItem(key,value);
    },
    getSessionStoarge: function (value) {
        return sessionStorage.getItem(value);
    },
    getObjFromStorage: function(key){
         let obj = sessionStorage.getItem(key)
        return JSON.parse(obj);
    },
    setDatatoSession:function(key,value){
        sessionStorage.setItem(key,value)
    },
    getVenueObj: function (json){
        let params = {};
        params['venueAddressId'] = json.venueAddressId;
        params['venueAddressLine1'] = json.venueAddressLine1;
        params['venueAddressLine2'] = json.venueAddressLine2;
        params['venueAddressLine3'] = json.venueAddressLine3;
        params['venueCity'] =  json.venueCity;
        params['venueCountry']= json.venueCountry;
        params['venueCounty'] = json.venueCounty;
        params['venueId'] = json.venueId;
        params['venueState']= json.venueState;
        params['venueText'] = json.venueText;
        params['seminarVenue'] = json.seminarVenue;
        params['venueZipCode'] = json.venueZipCode;
        return params
    },
    getSeminarObj : function(json){
        let params = {};
        params['seminarDate'] = json.seminarDate;
        params['seminarTime'] = json.seminarTime;
        params['seminarId'] = json.seminarId;
        params['seminarSubtenantId'] = json.seminarSubtenantId;
        params['seminarTopic'] = json.seminarTopic;
        params['isSeminarPaid'] = json.isSeminarPaid;
        params['seminarFee'] = json.seminarFee;
        params['seminarType'] = json.seminarType;
        params['subtenantId'] = json.subtenantId;
        params['noOfRegisteredSeats'] = json.noOfRegisteredSeats;
        params['noOfSeats'] = json.noOfSeats;
        

    
        return params;
    },
    getEventListSubtenantObj: function(json){
        konsole.log("json",json);
        let params = {};
        params['logoUrl'] = json.subtenantLogoUrl;
        params['seminarSubtenantId'] = json.subtenantId;
        return params;
    },
    getSeminarAttendee: function(json){
        let params = {};
        params['seminarAttendeeId'] = json.seminarAttendeeId;
        params['seminarAttendeeUserId'] =  json.seminarAttendeeUserId;
        params['attendeeEmailAddress'] = json.attendeeEmailAddress;
        params['attendeeFullName']= json.attendeeFullName;
        params['attendeeMobileNo'] = json.attendeeMobileNo;
        params['seminarId'] = json.seminarId;
        return params;
    },
    getSeminarAttendeeObj: function (json) {
        let params = {};
        params['seminarAttendeeId'] = json.seminarAttendieId;
        params['seminarAttendeeUserId'] = json.seminarAttendieUserId;
        params['attendeeEmailAddress'] = json.attendeeEmailAddress;
        params['attendeeFullName'] = json.attendieFullName;
        params['attendeeMobileNo'] = json.attendeeMobileNo;
        return params;
    },
    getFormattedTime: function(seminarTime){
        return moment(seminarTime, "HH:mm:SS").format("h:mm A");
    },
    getFormattedDate: function (seminarDate) {
        return moment(seminarDate).format('MMMM Do YYYY')
    },
    getFormattedDateNew: function (seminarDate) {
        const formattedDate = moment(seminarDate).format('dddd');
        const dayAndYear = moment(seminarDate).format('MMMM Do YYYY');
        return { formattedDate, dayAndYear };
    },
    GetQueryValues: (query, param) => {
        var url = "";
        url = query?.slice(query.indexOf('?') + 1).split('&');
        konsole.log(url);
        for (var i = 0; i < url.length; i++) {
            var urlparam = url[i].split('=');
            if (urlparam[0] === param) {
                return urlparam[1].split("#")[0];
            }
        }
        return "";
    },
    formatPhoneNumber:function(str) {
   
        let numValue = str?.slice(-10);
        let CountryCode = str?.slice(0,-10)

        let cleaned = ("" + numValue).replace(/\D/g, "");
    
    
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    
        if (match) {
          return CountryCode + " "+  "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
    
        return null;
      },
      mandatory:(str)=>{
        return `${str}*`
      },
    convertToUSFormat:(mobileNumber, countryCode)=> {
        const digitsOnly = mobileNumber.replace(/\D/g, '');
        const formattedNumber = digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, `+${countryCode} ($1) $2-$3`);
        return formattedNumber;
      },
      
    convertToIndiaFormat:(mobileNumber, countryCode)=> {
        const digitsOnly = mobileNumber.replace(/\D/g, '');
        const formattedNumber = digitsOnly.replace(/(\d{5})(\d{5})/, `+${countryCode} $1-$2`);
        return formattedNumber;
      },  

}

export default commonLib;