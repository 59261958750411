import { useCallback, useContext, useEffect, useState } from "react";
import '../App.css';
import commonLib from "../control/commonLib";
import { UserContext } from "../App";
import QRCode from "react-qr-code";
function SelfCheckingByQr(props) {
    const qrCodes = (props.location.state !== undefined) ? props.location.state : "";
    const seminarObj = commonLib.getObjFromStorage("seminarObj");
    const venueObj = commonLib.getObjFromStorage("venueObj");
    const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
    // konsole.log("iuyitugujg",qrCodes)
    let { subtenantLogo } = useContext(UserContext);
    return (
        <>
            <section className="qr-sec">
                <div className="scan-holder">
                    
                        <div className="para">
                        <p>Scan to Check-in</p>
                        </div>
                       
                        {/* <img src="icons/qrcode.png" alt="" className="inside_img" /> */}
                   
                        <div className="scan-image">
                        <QRCode
                                value={`UID=${qrCodes?.uid}&Stage=${"CHECKIN"}`}
                                viewBox={`0 0 256 256`}
                                // onClick={handleShow}
                                className="inside_img"
                            /> 
                           </div>
                         <div className="hadding">
                              {/* <h3>UI DESIGNER</h3> */}
                        <h3>{attendeeObj?.attendeeFullName}</h3>
                        {/* <h4>Happy Retirment life</h4> */}
                        <h4>{seminarObj?.seminarTopic}</h4>
                        {/* <h4>Mon, jan 23 2023, 4:00 PM PST</h4> */}
                        <h4>{commonLib.getFormattedDate(seminarObj?.seminarDate) +"," +commonLib.getFormattedTime(seminarObj?.seminarTime)}</h4>
                        {/* <h4>Westin's Hall</h4> */}
                        <h4>{venueObj?.venueText}</h4>
                        {/* <h4>23441 Madison Street ,Torrance, CA, USA</h4> */}
                        <h4>{venueObj?.venueAddressLine1}</h4>
                        </div>
                        
                    <div className="image-body">
                        <div className="">
                            {
                                subtenantLogo !== '' ? <img src={subtenantLogo} className='subtenantLogo  ' alt="Login user image" /> : <h2>SUBTENANT LOGO</h2>
                            }
                       
                         </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SelfCheckingByQr;