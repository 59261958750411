// import { Col, Form, Input, Row } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useState } from "react";
import Services from "../../../network/Services";
import konsole from "../../../network/konsole";

const Address = (props) => {
  const [address, updateAddress] = useState({});
  const addressRef = useRef(null);
  const [addressfiledErr, setAddressfieldErr] = useState(false);
  useEffect(() => {
    Services.initMapScript().then(() => initAutoComplete());
  }, []);

  const extractAddress = (place) => {
    const address = {
      address: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      plain() {
        const city = this.city ? this.city + ", " : "";
        const zip = this.zip ? this.zip + ", " : "";
        const state = this.state ? this.state + ", " : "";
        return city + zip + state + this.country;
      },
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }
    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;
    //   props.setaddressreg(component)
      konsole.log("address compoenen", component);
      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.zipcode = value;
      }

      if (types.includes("country")) {
        address.country = value;
      }
    });

    return address;
  };

  const onChangeAddress = (autocomplete) => {
    setAddressfieldErr(false);
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };

  const setAddress = (address) => {
    address.address = addressRef.current.value;

    updateAddress({
      city: address.city,
      state: address.state,
      zipcode: address.zipcode,
      country: address.country,
    });
    props.setaddressreg(address)
    konsole.log("asp", address);
    // props.form.setFieldsValue(address);
    if (
      address.address == "" ||
      address.city == "" ||
      address.state == "" ||
      address.zipcode == "" ||
      address.country == ""
    ) {
      alert("Please Enter valid address")
    }
  };

  const initAutoComplete = () => {
    if (!addressRef.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      addressRef.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  konsole.log("address", address);
  return (
    <div>
   
    
   <div className='col-md-12 form-group'>

<label for="formGroupExampleInput" className="form-label label-name-color fs-5" style={{paddingTop:"5px"}}>Address</label>
<input type="text" className="form-control bg-light fs-6" id="lastName" placeholder=" Enter Address (Optional)" ref={addressRef} />

</div>     
    

    </div>
  );
};

export default Address;
