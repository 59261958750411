import Config from '../config.json'
    const demo = Config.DEMO;
    // export const BASE_URL = (demo)? "https://aointakeuatapi.azurewebsites.net": "https://aointakeapi.azurewebsites.net";
    // export const BASE_URL_PAY = (demo)?"https://aopaymentdev.azurewebsites.net/Catalog/PaymentRequest" : "https://aopayment.azurewebsites.net/Catalog/PaymentRequest"
    // export const AoLoginBaseUrl = (demo) ? "https://aologinuat.azurewebsites.net/" : "https://aologin.azurewebsites.net/";
    // export const EventBaseUrl = (demo) ? "https://eventuat.azurewebsites.net/" : "https://eventdev.azurewebsites.net/";
    export const BASE_URL = (demo)? "https://aointakeuatapi.agingoptions.com": "https://aointakeapi.azurewebsites.net";
    export const LeadStackerBASE_URL = "https://rest.gohighlevel.com"
    export const BASE_URL_PAY = (demo)?"https://aopaymentdev.agingoptions.com/Catalog/PaymentRequest" : "https://aopayment.azurewebsites.net/Catalog/PaymentRequest"
    export const AoLoginBaseUrl = (demo) ? "https://aologinuat.agingoptions.com/" : "https://Unilogin.agingoptions.com/";
    export const EventBaseUrl = (demo) ? "https://eventuat.agingoptions.com/" : "https://events.agingoptions.com/";
let AoUrl ={
    getIpAddressUrlPath: 'https://geolocation-db.com/json/',
    getSubtenantDetailPath: 'api/Subtenant/get-subtenant-details',
    getSeminarPublishePath: 'api/SeminarPublisher/getSeminar',
    // registerWalkInUserPath: 'api/User/RegisterWalkInUser',
    registerWalkInUserPath: 'api/2/User/RegisterWalkInUser',
    getCountryCodeListPath: 'api/Country/GetRegistrationEnableCountry',
    getSeminarAttendeeAccessLinkPath: 'api/SeminarAttendeeAccessLink/ValidateAttendeeAccessLink',
    upsertSeminarAttendeePath: 'api/SeminarAttendee/upsertSeminarAttendee', //
    updateSeminarAttendeeInfoPath: 'api/SeminarAttendee/UpdateSeminarAttendeeStatus',
    getReferenceModePath: 'api/RefereneMode/GetReferenceMode',
    getPlannerCalenderPath:'api/PlannerCalendar/GetPlannerCalendarDetails',
    upsertPlannerBookingPath: 'api/PlannerBookings/upsertPlannerBookings',
    validateSeminarAttendeePath: 'api/SeminarAttendee/ValidateSeminarAttendee',
    registerWalkInUserForInvestPath: 'api/DinnerWithRajiv/RegisterSeminarUser',
    postAddUserOrder:'/api/UserOrder/AddUserOrder',
    getPlannerStatusUpdatePath :"/api/PlannerBookings/updatePlannerBookingStatus",
    getSendCheckInLinktoUser:"/api/User/SendCheckInLinkToUser?",
    postregisteraddress:'/api/Address/MemberAddress',
    GetUserRole : "/api/UserRole/GetUserRoleByUserId/",
    GetEmailTemplate : "/api/EmailTemplates/GetEmailTemplates?TemplateId=",
    PostEmailCom : "/api/EmailComm/SendEmailComm",
    userActivation : "/api/UserActivation/GetUserActivationLink",
    getSeminarUserListPath: 'api/SeminarUserList/getSeminarUserList',
    getTextTemplate: "/api/TextTemplates/GetTextTemplates",
    getCommMediumPath: '/api/CommMedium/GetCommMedium',
    postSendTextPath: '/api/TextComm/SendText',
    // getSeminarPublisherPath: 'api/SeminarPublisher/getSeminar',
    postClassRoomBooking: 'api/ClassroomBookings/Register',
    putPaymentBookingStatus :  'api/ClassroomBookings/SetPaymentStatus',
    putClassRoomBooking : 'api/ClassroomBookings',
    getClassRoomBooking : 'api/ClassroomBookings',
    userActivate11 : '/api/SeminarAttendee/UpdateSeminarAttendeeActiveStatus',
    getLeadstackerContacts : "v1/contacts/lookup",
    postLeadstackerContacts : "v1/contacts/",
    getPlannerPlans :"api/Planning",

}

export default AoUrl; 


