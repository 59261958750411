import { message } from "antd";
import konsole from "../network/konsole";
import Services from "../network/Services";
import commonLib from "./commonLib";
import config from "../config.json";
import {ConstantEmail,ConstantText} from './Constant'
let count = 0

const occurrenceId = {
    callOccurrenceIdFunc: (occurrenceValue, subtenantIdData, coordinatorIndex, allData)=>{
            let subtenantIdValue = subtenantIdData;
        
            let promise = Services.postgetCommMedium(occurrenceValue, true, subtenantIdValue)
            promise.then((response)=>{
                konsole.log("postgetCommMediumCommom",response)
                let commChannelId = response.data.data[0]?.commChannelId
                commChannelId == 3 ? occurrenceId.callBothTextAndEmailApiFunc(response.data.data[0]?.applicableEmailTempId,
                     response.data.data[0]?.applicableTextTempId,
                      occurrenceValue,
                       coordinatorIndex,allData)
                        : commChannelId == 2 ? occurrenceId.callTextApiFunc(response.data.data[0]?.applicableTextTempId, occurrenceValue, coordinatorIndex, allData) : commChannelId == 1 && occurrenceId.callEmailApiFunc(response.data.data[0]?.applicableEmailTempId, occurrenceValue, coordinatorIndex, allData);
            }).catch((error)=>{
                konsole.log("postgetCommMediumerror",error)
            }).finally(()=>{
                konsole.log("postgetCommMediumEnd")
            })

    },

    callOccurrenceIdFunc1: (occurrenceValue, subtenantIdData, coordinatorIndex, allData)=>{

        let subtenantIdValue = subtenantIdData;
        let promise = Services.postgetCommMedium(occurrenceValue, true, subtenantIdValue)
        promise.then((response)=>{
            konsole.log("postgetCommMediumCommom",response)
            let commChannelId = response.data.data[0]?.commChannelId
            commChannelId == 3 ? occurrenceId.callBothTextAndEmailApiFunc(response.data.data[0]?.applicableEmailTempId, response.data.data[0]?.applicableTextTempId, occurrenceValue, coordinatorIndex, allData) : commChannelId == 2 ? occurrenceId.callTextApiFunc(response.data.data[0]?.applicableTextTempId, occurrenceValue, coordinatorIndex, allData) : commChannelId == 1 && occurrenceId.callEmailApiFunc(response.data.data[0]?.applicableEmailTempId, occurrenceValue, coordinatorIndex, allData);
        }).catch((error)=>{
            konsole.log("postgetCommMediumerror",error)

            // if(error == "Error: Request failed with status code 404"  && count == 0){
            //     count = count + 1

            //     occurrenceId.callOccurrenceIdFunc(2)

            // }
        }).finally(()=>{
            konsole.log("postgetCommMediumEnd")
        })

},



    callBothTextAndEmailApiFunc: (emailTempalteId, textTempalteId, occurrenceValue, coordinatorIndex, allData) =>{
       occurrenceId.callTextApiFunc(textTempalteId, occurrenceValue, coordinatorIndex, allData)
       occurrenceId.callEmailApiFunc(emailTempalteId, occurrenceValue, coordinatorIndex, allData)
     },

    callEmailApiFunc: (TempalteId, occurrenceValue, coordinatorIndex, allData) =>{

        let promise = Services.GetEmailTemplateUser(TempalteId, true)
        promise.then((response)=>{
            konsole.log("callEmailApiFuncRes",response)
            let responseData = response.data.data[0]
            konsole.log("callEmailApiFuncRes",responseData)


            konsole.log("sampleeee",responseData, occurrenceValue, coordinatorIndex, allData)
          let  emailTo = commonLib.getObjFromStorage('user')?.post?.emailAddress;
          let  firstName = commonLib.getObjFromStorage('user')?.post?.firstName;
          const lastEightDigitArray = array => array.slice(-8);
              if(occurrenceValue === 1){
                if(lastEightDigitArray(emailTo) !== ConstantEmail){
                    // alert("1")
                    occurrenceId.sendEmailAPIFunc(responseData, occurrenceValue, coordinatorIndex, allData)
                }
                // else{
                //     alert(emailTo)
                // }

              }
              else{
                occurrenceId.sendEmailAPIFunc(responseData, occurrenceValue, coordinatorIndex, allData)
              }
           
           
        }).catch((error)=>{
            konsole.log("callEmailApiFuncErr",error)
        }).finally(()=>{
            konsole.log("callEmailApiFuncEnd")
        })
     },

     sendEmailAPIFunc: (responseData, occurrenceValue, coordinatorIndex, allData) =>{
        let emailTo;
        let ATTENDEENAME;
        // let SEATNOS attendees[0]?.allottedSeats[0]?.seatNo;
        let createdBy;
        let emailMappingTable;
        let seminarName 
        let EVENTVANUE 
        let EVENTADDRESS 
        let EVENTTIME 
        let EVENTDATE 
        let MAPLINK 
        let speakers 
        let SUBTENANTNAME

        let SPEAKERNAME;


        let templateData = responseData?.templateContent;




        if(occurrenceValue == 11 || occurrenceValue == 12 || occurrenceValue == 14 || occurrenceValue == 15){
            if(occurrenceValue == 12){

                emailTo = allData?.plannerEmailId
            }else if(occurrenceValue == 15){
                emailTo = allData?.coordinatorEmailId

            }
            
        else{
                emailTo = allData?.emailTo
            }
            emailMappingTable = allData?.emailMappingTablePKId
            createdBy = allData?.createdBy
            templateData =  occurrenceId.templateReplacer(templateData, allData,occurrenceValue)

            konsole.log("templateReplacertemplateReplacer",templateData)






        }else{

        
        if(occurrenceValue == 10){
            emailTo = commonLib.getObjFromStorage('seminarFullData')?.coordinators[coordinatorIndex]?.coordinatorEmailId;
            // emailTo = commonLib.getObjFromStorage('seminarFullData')
            konsole.log("emailToemailTo",emailTo)

        }
        
        else{

            emailTo = commonLib.getObjFromStorage('user')?.post?.emailAddress;
        }
         ATTENDEENAME = commonLib.getObjFromStorage('user')?.post?.firstName;
        //  SEATNOS = commonLib.getObjFromStorage('GetResponse')?.attendees[0]?.allottedSeats[0]?.seatNo;
         createdBy = commonLib.getObjFromStorage('seminarObjH')?.userId;
         emailMappingTable = commonLib.getObjFromStorage('user')?.post2?.seminarAttendeeId;
         seminarName = commonLib.getObjFromStorage('seminarObj')?.seminarTopic
         EVENTVANUE = commonLib.getObjFromStorage('seminarObj')?.seminarVenue
         EVENTADDRESS = commonLib.getObjFromStorage('seminarObj')?.venueAddressLine1
         EVENTTIME = commonLib.getObjFromStorage('seminarObj')?.seminarTime
         EVENTDATE = commonLib.getObjFromStorage('seminarObj')?.seminarDate
         MAPLINK = commonLib.getObjFromStorage('seminarObj')?.mapsLink
         speakers = commonLib.getObjFromStorage('seminarObj')?.speakers
         SUBTENANTNAME = commonLib.getObjFromStorage('seminarObj')?.subtenantName

         SPEAKERNAME = `${speakers[0]?.fName} ${speakers[0]?.lName}.`


         templateData = responseData?.templateContent;
         templateData = templateData.replace('@@SUBTENANTNAME',SUBTENANTNAME)
         templateData = templateData.replace('@@SEMINARNAME',seminarName)
         templateData = templateData.replace('@@SeminarName',seminarName)
         templateData = templateData.replace('@@SeminarTopic',seminarName)
         templateData = templateData.replace('@@ATTENDEENAME',ATTENDEENAME)
         templateData = templateData.replace('@@Seminarattendee',ATTENDEENAME)
         templateData = templateData.replace('@@SPEAKERNAME.',SPEAKERNAME)
         templateData = templateData.replace('@@EVENTVANUE',(EVENTVANUE !== null && EVENTVANUE !== undefined && EVENTVANUE !== ""? EVENTVANUE : ""))
         templateData = templateData.replace('@@EVENTADDRESS',(EVENTADDRESS !== null && EVENTADDRESS !== undefined && EVENTADDRESS !== ""? EVENTADDRESS : ""))
         templateData = templateData.replace('@@EVENTTIME',`${commonLib.getFormattedDate(EVENTDATE)}, ${commonLib.getFormattedTime(EVENTTIME)}`)
         templateData = templateData.replace('@@SeminarDate',commonLib.getFormattedDate(EVENTDATE))
         templateData = templateData.replace('@@SeminarTime', commonLib.getFormattedTime(EVENTTIME))
         templateData = templateData.replace('@@MAPLINK',MAPLINK)
    }
        //  templateData = templateData.replace('@@SEATNOS',SEATNOS)

        

        

        const promise = Services.SendEmailCom(responseData?.templateType, emailTo, responseData?.emailSubject, responseData?.templateId, templateData, 1, "tblSeminarAttendeeList", emailMappingTable, createdBy)
        promise.then((response)=>{
            konsole.log("sendEmailAPIFuncRes",response)
            // message.success("Email Send Successfully")
        }).catch((error)=>{
            konsole.log("sendEmailAPIFuncerr",error)
        }).finally(()=>{
            konsole.log("sendEmailAPIFuncEnd")
        })
     },

     callTextApiFunc: (TempalteId, occurrenceValue, coordinatorIndex, allData) =>{
        let promise = Services.getTextTemplateFunc(TempalteId, true)
        promise.then((response)=>{
            
          let textTo1 = commonLib.getObjFromStorage('user')?.post?.phoneNumber
          konsole.log("callTextApiFuncRes1",textTo1)
            const lastTenDigitArray = array => array.slice(-10);
            let responseData = response.data.data[0]
            if(occurrenceValue == 1){
                if(lastTenDigitArray(textTo1) !== ConstantText){
                    // alert("1")
                    occurrenceId.sendTextAPIFunc(responseData, occurrenceValue, coordinatorIndex, allData)
                }

            }
            else{
                occurrenceId.sendTextAPIFunc(responseData, occurrenceValue, coordinatorIndex, allData)
            }
            
        }).catch((error)=>{
            konsole.log("callTextApiFuncErr",error)
        }).finally(()=>{
            konsole.log("callTextApiFuncEnd")
        })
    },

    sendTextAPIFunc: (responseData, occurrenceValue, coordinatorIndex, allData) =>{
        let templateData = responseData?.textTemplateContent;

        let textTo;
        let completePhnNo;
        let createdBy;
        let textMappingTable;
        let seminarName;
        let seminarDate;
        let seminarTime;


        if(occurrenceValue == 11 || occurrenceValue == 12 || occurrenceValue == 14 || occurrenceValue == 15){

            if(occurrenceValue == 14 ){
                textTo = allData?.ATTENDEECELLNO
                completePhnNo = allData?.ATTENDEECELLNO
                textMappingTable = allData?.emailMappingTablePKId
                createdBy = allData?.createdBy
                
            }else if(occurrenceValue == 11){
                textTo = allData?.ATTENDEECELLNO
                completePhnNo = allData?.ATTENDEECELLNO
                textMappingTable = allData?.emailMappingTablePKId
                createdBy = allData?.createdBy
            }else if(occurrenceValue == 12){
                completePhnNo = allData?.plannerMobileNo
                textTo = allData?.plannerMobileNo
                textMappingTable = allData?.emailMappingTablePKId
                createdBy = allData?.createdBy
                
            }
            else if(occurrenceValue == 15){
                completePhnNo = allData?.COORDINATORNUMBER
                textTo = allData?.COORDINATORNUMBER
                textMappingTable = allData?.emailMappingTablePKId
                createdBy = allData?.createdBy

            }
            
           

konsole.log("1112if",textTo)
            templateData = occurrenceId.templateReplacer(templateData, allData, occurrenceValue)

        }else{

            if(occurrenceValue == 10){
                completePhnNo = commonLib.getObjFromStorage('seminarFullData')?.coordinators[coordinatorIndex]?.coordinatorMobileNo;
                textTo = commonLib.getObjFromStorage('seminarFullData')?.coordinators[coordinatorIndex]?.coordinatorMobileNo;
                textMappingTable = commonLib.getObjFromStorage('user')?.post2?.seminarAttendeeId
                createdBy = commonLib.getObjFromStorage('seminarObjH')?.userId;

            }else{
                textTo = commonLib.getObjFromStorage('user')?.post
                completePhnNo = `${textTo?.countryCode}${textTo?.phoneNumber}`
                createdBy = commonLib.getObjFromStorage('seminarObjH')?.userId;
                textMappingTable = commonLib.getObjFromStorage('user')?.post2?.seminarAttendeeId;

            }

            konsole.log("1112else",textTo, coordinatorIndex)

            
            
         seminarName = commonLib.getObjFromStorage('seminarObj')?.seminarTopic
         seminarDate = commonLib.getObjFromStorage('seminarObj')?.seminarDate
         seminarTime = commonLib.getObjFromStorage('seminarObj')?.seminarTime

        konsole.log("seminarTimeseminarTime",seminarName, commonLib.getFormattedDate(seminarDate), commonLib.getFormattedTime(seminarTime))
         templateData = templateData?.replace('@@SEMINARNAME',seminarName)
         templateData = templateData?.replace('@@seminarname',seminarName)
         templateData = templateData?.replace('@@SeminarName',seminarName)
         templateData = templateData?.replace('@@SEMINARDATE',commonLib.getFormattedDate(seminarDate))
         templateData = templateData?.replace('@@seminardate',commonLib.getFormattedDate(seminarDate))
         templateData = templateData?.replace('@@SeminarDate',commonLib.getFormattedDate(seminarDate))
        // let templateData3 = templateData2?.replace('@@SEMINARTIME ',commonLib.getFormattedTime(seminarTime))
         templateData = templateData?.replace('@@SEMINARTIME. ',`${commonLib.getFormattedTime(seminarTime)}. `)
         templateData = templateData?.replace('@@seminartime. ',`${commonLib.getFormattedTime(seminarTime)}. `)
         templateData = templateData?.replace('@@SeminarTime. ',`${commonLib.getFormattedTime(seminarTime)}. `)

        }


        let promise = Services.postSendText(responseData?.textTemplateType, completePhnNo, templateData, responseData?.textTemplateId, 1, "tblSeminarAttendeeList", textMappingTable, createdBy)
        promise.then((response)=>{
            konsole.log("sendTextAPIFuncRes", response,completePhnNo,templateData)
            // message.success("Message Send Successfully")
        }).catch((error)=>{
            konsole.log("sendTextAPIFuncError", error)
        }).finally(()=>{
            konsole.log("sendTextAPIFuncEnd")
        })
    },



    templateReplacer: (templateData, allData, occurrenceIdDataValue)=>{
        let updatedTemplateData = templateData;



    konsole.log("AttendeeNameAttendeeName",allData)
    konsole.log("AttendeeNameAttendeeName11",templateData)
updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEENAME,',allData?.ATTENDEENAME)
updatedTemplateData = updatedTemplateData?.replace('@@attendeename,',allData?.ATTENDEENAME)
updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEENAME',allData?.ATTENDEENAME)
updatedTemplateData = updatedTemplateData?.replace('@@ATENDEENAME',allData?.ATTENDEENAME)
updatedTemplateData = updatedTemplateData?.replace(' @@ATTENDEENAME ',allData?.ATTENDEENAME)
updatedTemplateData = updatedTemplateData?.replace('@@AttendeeName',allData?.ATTENDEENAME)
updatedTemplateData = updatedTemplateData?.replace('@@AttendeeName',allData?.ATTENDEENAME)
updatedTemplateData = updatedTemplateData?.replace('@@PlannerDate',allData?.PlannerDate)
updatedTemplateData = updatedTemplateData?.replace('@@plannerdate',allData?.PlannerDate)
updatedTemplateData = updatedTemplateData?.replace('@@PLANNERDATE',allData?.PlannerDate)
updatedTemplateData = updatedTemplateData?.replace('@@PlannerTime',allData?.PlannerTime)
updatedTemplateData = updatedTemplateData?.replace('@@plannertime',allData?.PlannerTime)
updatedTemplateData = updatedTemplateData?.replace('@@PLANNERTIME',allData?.PlannerTime)
updatedTemplateData = updatedTemplateData?.replace(/@@UNIQUELINK/g,allData?.UNIQUELINK)
updatedTemplateData = updatedTemplateData?.replace('@@uniquelink',allData?.UNIQUELINK)
updatedTemplateData = updatedTemplateData?.replace('@@INTAKELINK',allData?.UNIQUELINK)
updatedTemplateData = updatedTemplateData?.replace('@@intakelink',allData?.UNIQUELINK)
updatedTemplateData = updatedTemplateData?.replace(' @@UNIQUELINK ',allData?.UNIQUELINK)
updatedTemplateData = updatedTemplateData?.replace('@@BookingStatus',allData?.bookingStatusName +"-"+ allData?.PLANNINGNAME)
updatedTemplateData = updatedTemplateData?.replace('@@BOOKINGSTATUS',allData?.bookingStatusName +"-"+ allData?.PLANNINGNAME)
updatedTemplateData = updatedTemplateData?.replace('@@PlannerName',allData?.PlannerName)
updatedTemplateData = updatedTemplateData?.replace('@@plannername',allData?.PlannerName)
updatedTemplateData = updatedTemplateData?.replace('@@PLANNERNAME',allData?.PlannerName)
updatedTemplateData = updatedTemplateData?.replace('@@AttendeeEmail',allData?.emailTo)
updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEEEMAIL',allData?.ATTENDEEEMAIL)
updatedTemplateData = updatedTemplateData?.replace('@@COORDINATORNAME',allData?.COORDINATORNAME)
updatedTemplateData = updatedTemplateData?.replace('@@coordinatorname',allData?.COORDINATORNAME)
updatedTemplateData = updatedTemplateData?.replace('@@SUBTENANTNAME',allData?.SUBTENANTNAME)
updatedTemplateData = updatedTemplateData?.replace('@@subtenantname',allData?.SUBTENANTNAME)
updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEECELLNO',allData?.ATTENDEECELLNO)
updatedTemplateData = updatedTemplateData?.replace('@@attendeecellno',allData?.ATTENDEECELLNO)
updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEECELLNO',allData?.ATTENDEECELLNO)
updatedTemplateData = updatedTemplateData?.replace('@@PlanningName', allData?.PLANNINGNAME)




    
  


            konsole.log("updatedTemplateData",updatedTemplateData)

         
        return updatedTemplateData
        
        
        // }

    }





}


export default occurrenceId;