import { BackTop, Card, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import konsole from '../../../network/konsole'
import LayoutComponent from '../../LayoutComponent'
import Commonregcomponent from '../commonComponent/Commonregcomponent'
import "../Readmoreclassroom/readMoreclassroom.css"
import commonLib from '../../../control/commonLib'
import { useHistory } from 'react-router-dom'
import Services from '../../../network/Services'
import Payment from '../../SelfPlanner/VenuePayment/Payment'

const Readmoreclassroom = (props) => {
    const [showPayment, setshowPayment] = useState("")
    const history = useHistory()
    const classRoomStatus = commonLib.getObjFromStorage("newRegisterUserDat");
    const classRoomSeminar = commonLib.getObjFromStorage("classRoomSeminar");
    const attendeeObj = commonLib.getObjFromStorage("attendeeObj");

    const selectedUser = props.location.state !== undefined ? props.location.state : {}
    konsole.log("ssd",selectedUser)
        
     
    const classRoomRegistration =(userId,seminarAttendeeId,seminarId,classroomId,createdBy)=>{
        let postData = Services.postClassRoomRegister(userId,seminarAttendeeId,seminarId,classroomId,createdBy)
        konsole.log("mew11",userId,seminarAttendeeId,seminarId,classroomId,createdBy)
        postData.then((res) => {
    
       
          konsole.log("responseresponse", res.data.data)
          let classbookingId = res.data.data?.bookingId;
          commonLib.setDatatoSession("classbookingId", JSON.stringify(classbookingId))


    
        }).catch((err) => {
          konsole.log("errWW", err)
        })
      }
    const toBookClass = (selectedUser) =>{
        konsole.log("sett",selectedUser)
        const readmoreBook = true;
        commonLib.setDatatoSession("readmoreBook",JSON.stringify(readmoreBook))
        commonLib.setDatatoSession("readmoreBookData",JSON.stringify(selectedUser))
        // konsole.log("hyyy",selectedUser)
        // commonLib.setDatatoSession("classVenue",JSON.stringify(allData))
        classRoomRegistration(
            attendeeObj?.seminarAttendeeUserId,
            attendeeObj?.seminarAttendeeId
            ,attendeeObj?.seminarId,
             selectedUser?.classRoomData?.seminarId,
            attendeeObj?.seminarAttendeeUserId)
            
          konsole.log("jshjdhjshdj",classRoomSeminar,classRoomSeminar?.noOfSeats !== classRoomSeminar?.noOfRegisteredSeats)
        if (classRoomSeminar?.noOfSeats != classRoomSeminar?.noOfRegisteredSeats) {
            setshowPayment(true)
            // history.push({
            //             pathname: '/classroomvanupayment', 
            //             state:  selectedUser, 
            //         })
        }
        else{
            alert("No Seats Available")
        }

        // if(classRoomStatus === true){
        //     history.push({
        //         pathname: '/newClassroomRegistration', 
        //         state:  selectedUser.classRoomData, 
        //     })

        // }
        // else{
        //     history.push({
        //         pathname: '/classroomvanupayment', 
        //         state:  selectedUser, 
        //     })
        // }
     
    }

    const read =()=>{
        history.push({
            pathname: '/Classroomreg', 
            state:  selectedUser.useData, 
        })

    }
    return (
        <div>
            <LayoutComponent>
                <Row className='reee readMoreclassroom-main'>
                    <Col span={24} className='p-3'>
                        <Commonregcomponent text="Book a Workshop" topic="Enjoy your retired life"/>
                        {/* <div className='d-flex'>
                            <h5 className='ClassReg-Tag-h4'>Classroom Registration: &nbsp;</h5>
                            <h5>
                                Book a Classroom
                            </h5>
                        </div>
                        <div className='d-flex'>
                            <h6 className='ClassReg-Tag-h4'>Topic : &nbsp;</h6><h6 className='ClassReg-Tag-h4'>Enjoy your retired life</h6>
                        </div> */}
                        <Row span={24} className="ps-2 pb-2 bg-white">
                            <Col span={24}>
                                <Row className='mt-2 mb-1'>
                                    <Col span={24}onClick={()=>read()}>
                                        {/* <Link to="Classroomreg" style={{ color: "black" }}onClick={()=>Back()}>{"<"} */}
                                         All Workshop
                                         {/* </Link> */}
                                    </Col>
                                </Row>
                                <Row className='main-row-readMore'  >
                                    <Col span={24} className="p-2" >
                                        <Row>
                                            <Col span={20} xs={24} sm={20} md={20} lg={20} xl={20}>
                                                <div className=' class-img-div-show'>

                                                    <img variant="top" src="cardImage.png" className='img-fluid' />
                                                </div>

                                                <h3 className='Topic-tag-h3 mt-1'>{selectedUser.classRoomData?.seminarTopic}<span className='Topic-tag-span'>({selectedUser.classRoomData?.seminarType})</span></h3>

                                            </Col>
                                            <Col span={4} xs={24} sm={4} md={4} lg={4} xl={4} >
                                                <div className='d-flex justify-content-end mt-1 Book-Now-Button-Div-show'>
                                                    {/* <Link to="classroomvanupayment"> */}
                                                        <button type="button" class="btn outline fw-bold Book-Now-Button"onClick = {()=>toBookClass(selectedUser)} >Book Now</button>
                                                    {/* </Link> */}
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className='mt-1'>
                                            <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16} className="">
                                                <div>
                                                    <h6 style={{ color: "#707070" }}>By {""}
                                                        <Link to="readmoreprofessclassroom">
                                                            <span style={{ color: "#720C20" }} >{selectedUser?.classRoomData.speaker.fName}</span>
                                                        </Link>
                                                    </h6>
                                                    <h6 className='h6-color-tag' >
                                                       Date: {commonLib.getFormattedDate(selectedUser?.classRoomData?.seminarDate)} - {commonLib.getFormattedDate(selectedUser?.classRoomData?.toDate)}
                                                    </h6>
                                                    <h6 className='h6-color-tag' >
                                                      Time:  {commonLib.getFormattedTime(selectedUser?.classRoomData?.seminarTime)} - {commonLib.getFormattedTime(selectedUser?.classRoomData?.toTime)}
                                                    </h6>
                                                    <h6 className='h6-color-tag' >
                                                       Fee: ${selectedUser?.classRoomData?.seminarFee}
                                                    </h6>
                                                    <div className='d-flex justify-content-start ' >
                                                        <h6 className='h6-color-tag'>
                                                            {selectedUser?.classRoomData?.venueAddressLine}
                                                        </h6>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8} className="d-flex justify-content-end">
                                                <div className='p-1 Class-Img-Div' style={{width:"240px"}}>

                                                    <img variant="top" src="cardImage.png" className='img-fluid' />
                                                </div>

                                                <div className='d-flex justify-content-end mt-1 Book-Now-Button-Div '>
                                                    {/* <Link to="classroomvanupayment"> */}
                                                        <button type="button" class="btn outline fw-bold Book-Now-Button" onClick = {()=>toBookClass(selectedUser)} >Book Now</button>
                                                    {/* </Link> */}
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className='mt-3'>
                                            <Col span={3} xs={24} sm={24} md={3} lg={3} xl={3}>
                                                <div className='Class-Img-prof-Div' >
                                                    <img variant="top" src={selectedUser?.classRoomData.speaker?.speakerProfileUrl} className='Class-Img-prof-Div img-fluid' />
                                                </div>
                                            </Col>
                                            <Col span={21} xs={24} sm={24} md={21} lg={21} xl={21}>
                                                <div>
                                                    <h6 className='desc-Tag-h6' >
                                                        <span className='desc-Tag-span'> {selectedUser?.classRoomData.speaker?.fName} {selectedUser?.classRoomData.speaker?.lName} </span>
                                                        {selectedUser?.classRoomData.speaker?.speakerProfile}
                                                    </h6>
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row className='mt-2'>
                                            <Col span={24}>
                                                <h6 className='about-classroom-tag-h6' >About Workshop</h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} >
                                                <h6 className='desc-classroom-tag-h6' >{selectedUser?.classRoomData.speaker?.description}
                                                </h6>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                    </Col>
                </Row>
            </LayoutComponent>
            {
          showPayment ?   <Payment 
        //   paymentMethod={paymentMethod} 
        //   classData = {newRegisterUserDat === true ?classNewUserData:state}
        classData = {selectedUser}
           attendeeObj={attendeeObj} />:''
        }
        </div>
    )
}

export default Readmoreclassroom