import React, { useState, useRef, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form'
import commonLib from '../../control/commonLib';
import { selfWalkInId, selfCheckInId, EnquiryRegistrationId, CounterCheckInId } from '../../control/Constant';
import konsole from '../../network/konsole';
import Services from '../../network/Services';
import './register.css';
import { useHistory } from 'react-router-dom';
import Toaster from '../../Toaster/Toaster';
import LayoutComponent from '../LayoutComponent';
import { UserContext } from '../../App';
import PhoneNumberMasking from "../PhoneNumberMask"
import Address from '../selfchecking/RegisterNow/Address';



const ClassRoomNewRegistration = (props) => {
  // konsole.log("zzz",props)
  const { setLoader } = useContext(UserContext);
  const history = useHistory();
  konsole.log("useHistory", history);
  const[addressreg,setaddressreg]=useState('')
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [contactnum, setcontactnum] = useState();
  const venueObj = commonLib.getObjFromStorage('venueObj');
  const attendeeObj = commonLib.getObjFromStorage('attendeeObj');
  const getseminarObjdata = commonLib.getObjFromStorage("seminarObj");
  
  const classRoom = (props.location.state !== undefined) ? props.location.state : [{}];
  
  const [phonemsg, setphonemsg] = useState("")
  const [referenceModeList, setReferenceMode] = useState([]);
  const [registeredUser, setRegisteredUser] = useState(true);
  const [clientIpAddress, setClientIpAddress] = useState('');
  konsole.log("yes",classRoom)
 
  useEffect(() => {
    getIpAddress();
    getCountryCodeList();
    getReferenceMode();
  }, [])

  const getIpAddress = () => {
    let promise = Services.getIpAddress();
    promise.then(res => {
      konsole.log("IP address78", res);
      setClientIpAddress(res.IPv4);
    })
  }

  const getReferenceMode = () => {
    let promise = Services.getReferenceMode();
    promise.then(res => {
      konsole.log("reference mode", res);
      setReferenceMode(res.data.data)
    })
  }

  const getCountryCodeList = () => {
    const promises = Services.getCountryCodeList();
    promises.then(res => {
      konsole.log('countryCodeList', res);
      setCountryCodeList(res.data.data)
    })
      .catch(err => {
        konsole.log("err", err);
      }).finally(fin => {
        konsole.log("finish");
      })
  }

  const { register, handleSubmit, setError, watch, setValue, clearErrors, formState: { errors } } = useForm();
 
  const phoneNo = watch("phoneNo");


  const onSubmit = data => {

    if(phoneNo == undefined || phoneNo == ""){
      setError("phoneNo", { type: "error", message: "Please enter the Cell Number"})
      return;
    }
    else if(phoneNo !== undefined && phoneNo !== "" && phoneNo?.length < 10){
      setError("phoneNo", { type: "error", message: "Enter the valid Cell Nummber" })
      konsole.log("watch list", watch("phoneNo"));
      return;
    }

    let userRegistration = data;
    userRegistration['roleId'] = 2;
    userRegistration['noOfAttendee'] = parseInt(userRegistration.noOfAttendee);
    userRegistration['referenceMode'] = parseInt(userRegistration.referenceMode);
    userRegistration['clientIPAddress'] = clientIpAddress;
    userRegistration['countryCode'] = '+1';
    userRegistration['semUserStatusId'] = EnquiryRegistrationId;
    userRegistration['phoneNumber'] = phoneNo;
   
   konsole.log("value",userRegistration)

    
     
      
    registerNewUser(745,classRoom.seminarId,userRegistration);
        
      
     
     

  }




  const registerNewUser = (subtenantId, seminarId, userRegistration) => {
    const promise = Services.registerNewUser(subtenantId, seminarId, userRegistration)
    konsole.log('register user ', subtenantId, seminarId, userRegistration);

    promise.then(res => {
       konsole.log("user",res)
       let userData = {};
        userData['attendeeFullName'] = userRegistration.firstName +" "+ userRegistration.lastName;
        userData['attendeeEmailAddress'] = userRegistration.emailAddress;
        userData['attendeeMobileNo'] = userRegistration.phoneNumber;
        userData['seminarAttendeeUserId'] = res.data.data.userId;
        userData['seminarId'] = res.data.data?.seminarId;
        userData['seminarAttendeeId'] = res.data.data?.seminarAttendeeId;

        
       

       const pay = true
       
       const allData1 = {classRoomData:classRoom,useData:userData}
       commonLib.setDatatoSession("newRegisterUserData",JSON.stringify(allData1))
       
       commonLib.setDatatoSession("newRegisterUserPay",JSON.stringify(pay))
       
       const allData = {classRoomData:classRoom,useData:userData}
       
       if(addressreg !=='' && addressreg !==undefined && addressreg !==null){
       let jsonforaddress={
          "userId":res.data.data.userId,
          "address": {
            "lattitude": "",
            "longitude": "",
            "addressLine1":addressreg?.address,
            "addressLine2": "",
            "addressLine3": "",
            "zipcode": addressreg?.zipcode,
            "city": addressreg?.city,
            "state": addressreg?.state,
            "country": addressreg?.country,
            "addressTypeId": 1,
            "createdBy": res.data.data.userId
          }
        }

        let promise=Services.postaddressbyuserid(jsonforaddress)
        promise.then((res)=>{
          konsole.log("resressaddress",res)
         

        }).catch((err)=>{
          konsole.log("errr",err)
        })

      }else{
        history.push({
          pathname : '/classroomvanupayment',
          state: allData
         })

      }

      


 })
      .catch(err => {
        konsole.log("err", err);
        // Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        konsole.log("finish", fin);
      })
  }

  

  
 

  // const onchangeContact = (value) => {
  //   // konsole.log("value", value.length);
  //   // value.length > 0 ? 

  //   setcontactnum(value)
  // };

 const registeroptions = {
    firstName: {
      required: "Please enter your first name",
      pattern: {
        value: /[A-Z a-z]/,
        message: "Please enter the valid first name"
      }
    },
    lastName: {
      required: "Please enter your last name",
      pattern: {
        value: /[A-Z a-z]/,
        message: "Please enter the valid last name"
      }
    },
    emailAddress: {
      required: "Please enter Email address",
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please enter valid Email Id',
      }
    },
    phoneNumber: {
      required: "Please enter Cell number",
      // maxLength:{
      //   value:10,
      //   message:"Please enter valid Mobile number"
      // },
      pattern: {
        value: /^([+]\d{2})?\d{10}$/,
        message: "Please enter valid  Cell number"
      },
      validate: {
        ValueNull: (value) => value !== "",
        NumberExcess: (value) => value.length === 10
      }
    },
    noOfAttendee: {
      required: "Please enter No of attendence",
      pattern: {
        value: /^[1-9]/,
        message: "Only Digit Allow"
      },
      minLength: {
        vlaue: 1,
        message: "Please enter no of attendence"
      }

    },
    // leadFrom: {
    //   required: "Lead from is required*",
    //   pattern: {
    //     value: /[A-Z a-z]/,
    //     message: "Only Characters allow"
    //   }
    // },

  }

  const onchangeContact = (value) => {
    // konsole.log("value", value.length);
    // value.length > 0 ? 

    setcontactnum(value)
  };

  // const selectOptionsList = (value, label) => {
  //   konsole.log("value", label);
  // }

  const back=()=>{
    history.push({
      pathname: "/Classroomreg"
    })
  }


  return (<div>
     <>
      <LayoutComponent>
        <div className='container-fluidn selfClassroom-main'>

          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <div className=' mt-4 p-0' >
              <h3 >Workshop<b> : New Registration</b></h3>
             
            </div>
           
            

          </div>

          <form className='container-fluid  bg-white' onSubmit={onSubmit}>

            <div className='row mb-3 pt-3'>
              <div className='col-md-6  form-group'>
                <label for="formGroupExampleInput" className="form-label label-name-color fs-5">First Name </label>
                <input type="text" className="form-control bg-light fs-6" id="firstName"
                  placeholder="Enter First Name" {...register("firstName", registeroptions.firstName)}></input>
                {/* {errfirstName ? <span className="text-dark">Only Character Enter</span> : null} */}
                {/* {errors.firstName && <span className="RegistrationValidationErrPrint">First Name is Required</span>} */}

                {<p className="ps-3 text-danger">{errors?.firstName && errors.firstName.message}</p>}
              </div>

              <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput" className="form-label label-name-color  fs-5">Last Name</label>
                <input type="text" className="form-control bg-light fs-6" id="lastName" placeholder=" Enter Last Name" {...register('lastName', registeroptions.lastName)}></input>

                {<p className="ps-3 text-danger">{errors?.lastName && errors.lastName.message}</p>}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-md-6'>
                <label for="formGroupExampleInput2" className="form-label label-name-color  fs-5 ">Phone Number</label>
                <div className='row gap-4 gap-md-0'>
                  {/* <div className='col-md-3 m-0'>
                    <select className=" form-select p-1 bg-light fs-7 selectpicker bg-light dropdown-item  border  fs-6" {...register('countryCode', { required: true })} placeholder="Code">
                      {
                        countryCodeList.map((code, index) => {
                          return <option value={code.value}>{code.label}</option>
                        })
                      }
                    </select>
                  </div> */}
                  <div className='col-md-12 m-0'>
                  <PhoneNumberMasking id ="phoneNumber"className="form-control bg-light fs-6" setValue={setValue} setError={setError} setClearError={clearErrors}/>
                  {errors.phoneNo && errors.phoneNo?.type == "error" && (<p className='ps-3 text-danger'>{errors.phoneNo.message}</p>)}
                  {/* <p className="ps-3 text-danger" >{phonemsg}</p> */}
                    {/* <input type="tel" className="form-control bg-light fs-6" id="phoneNumber" placeholder="Enter Phone Number"  {...register('phoneNumber', registeroptions.phoneNumber)} ></input> */}
                  </div>
                </div>
                {/* {<p className="ps-3 text-danger">{errors?.phoneNumber && errors.phoneNumber.message}</p>} */}
              </div>
              <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput2" className="form-label label-name-color  fs-5 ">Email </label>
                <input type="text" className="form-control bg-light fs-6" id="emailId" placeholder="Enter Email"
                  {...register('emailAddress', registeroptions.emailAddress)} ></input>
                {<p className="ps-3 text-danger">{errors?.emailAddress && errors.emailAddress.message}</p>}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput2" className="form-label label-name-color fs-5">No. of Attendees</label>
                <input type="number" className="form-control bg-light fs-6" id="noOfAttendee" min={0} placeholder="Enter No. of Attendees"  {...register('noOfAttendee', registeroptions.noOfAttendee)}></input>
                {<p className="ps-3 text-danger">{errors?.noOfAttendee && errors.noOfAttendee.message}</p>}
              </div>
              {/* <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput2" className="form-label label-name-color fs-5">Lead From</label>
                <input type="text" className="form-control bg-light fs-6" id="leadFrom" placeholder=" Enter Lead From" {...register('leadFrom', registeroptions.leadFrom)}></input>

                {<p className="ps-3 text-danger">{errors?.leadFrom && errors.leadFrom.message}</p>}
              </div> */}
              <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput" className="form-label label-name-color fs-5">How did you hear about us?</label>
                
                 
                  <select className=" form-select p-1 bg-light fs-7 selectpicker height-20px bg-light dropdown-item  border  fs-6" {...register('referenceMode')} placeholder="How did you hear about us?">
                    <option value="">How did you hear about us?</option>
                    {
                      referenceModeList.length > 0 && referenceModeList.map((reference, index) => {
                        return <option value={reference.value}>{reference.label}</option>
                      })
                    }
                  </select>
                
                {errors.title && <span className="ps-3 label-name-color">Required*</span>}
              </div>
              
            </div>

            <div className='row mb-4'>
              <Address  setaddressreg={setaddressreg}/> 
            </div>
            
            
            {/* <div className='row mb-2'>
            </div> */}
          
            <div className='row pb-4 justify-content-end   mt-2' >
              <div className='col-6' >
                <button className='btn  text-white  w-50 p-2 mt-2 float-left newroomNewRegisterbuttonbakk rounded' style={{ backgroundColor: '#720c20' }} type='button' id='btnBack' onClick={back} >Back </button>
              </div>
              <div className='col-6' >
                <button className='btn  text-white  w-50 p-2 mt-2 float-end rounded newroomNewRegisterbuttonreg' style={{ backgroundColor: '#720c20' }} type='button' id='btnRegister' onClick={handleSubmit(onSubmit)} >Register Now </button>
              </div>
            </div>
          </form>
        </div>
      </LayoutComponent>
    
    </>
  </div>)
    
    
   

  
  



  
}

export default ClassRoomNewRegistration;