import React, { useCallback, useContext, useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useHistory } from "react-router-dom";
import { UserContext } from "../App";
import commonLib from "../control/commonLib";
import { selfCheckingCheckedIn, selfCheckInId } from "../control/Constant";
import konsole from "../network/konsole";
import Services from "../network/Services";
import Toaster from "../Toaster/Toaster";

const QrCode = (props) => {
    const isMounted = true;
    const [qrCode, setQRCode] = useState({
        uId: "",
        stage: ""
    });
    const { setLoader } = useContext(UserContext);
    const history = useHistory();



    useEffect(() => {
        // alert("1")
        if(qrCode.uId !== "" && qrCode.stage !== ""){
            // alert("2")
            getSeminarAttendeeAccessLink(qrCode.uId, qrCode.stage);
        }
    
      return () => {
        // konsole.clear();
      }
    }, [qrCode.uId])
    


    const getSeminarAttendeeAccessLink = useCallback((uniqueKey, stage) => {
        konsole.log("run getSeminarAttendeeAccessLink")
        setLoader(true);
        // konsole.log("upt",uniqueKey)
        const promises = Services.getSeminarAttendeeAccessLink(uniqueKey)
        promises.then(res => {
            setLoader(false);
            konsole.log('seminar Token Key111', res.data.data);
            const accessObj = res.data.data;
            const accessObj1 = res.data.data;
            commonLib.setSessionStoarge('venueObj2', JSON.stringify(accessObj1));
            saveInStorage(accessObj, stage);
        })
            .catch(err => {
                konsole.log("err", err.response);
                Toaster.error(err.response.data.messages[0])
            }).finally(fin => {
                konsole.log("finish");
                setLoader(false);
            })
    },[])

    const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy) => {
        setLoader(true);
        const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
        promises.then(res => {
            setLoader(false);
            konsole.log('registered Checkin res', res);
            commonLib.setDatatoSession("GetResponse", JSON.stringify(res.data.data))
            const selfWalkInCheckInObj = res.data.data.attendees[0];

            const bookedSeats = { sheets: selfWalkInCheckInObj.allottedSeats };
            history.push({
                pathname: '/Congratulations',
                state: bookedSeats
            })
        })
            .catch(err => {
                konsole.log("errs", err.response);
                Toaster.error(err.response.data.messages[0])
            }).finally(fin => {
                konsole.log("finish");
                setLoader(false);
            })
    }

    const validateSeminarAttendee = (subtenantId, seminarId, contact, status) => {
        setLoader(true);
        const promises = Services.validateSeminarAttendee(subtenantId, seminarId, contact, status);
        promises.then(res => {
            setLoader(false);
            konsole.log("responsvie", res);
            const validateUserResponse = res.data.data[0];
            const attendeeSeminarId = validateUserResponse.seminarAttendeeId;
            const attendeeUserId = validateUserResponse.seminarAttendeeUserId;

            handleWalkInCheckInUser(seminarId, attendeeSeminarId, attendeeUserId, selfCheckInId, attendeeUserId);
        })
            .catch(err => {
                konsole.log("errd", err.response);
                Toaster.error(err.response.data.messages[0])
            }).finally(fin => {
                konsole.log("finish");
                setLoader(false);
            })
    }


    const saveInStorage = (accessObj, stage) => {
        konsole.log("ssf", accessObj)
        const venueObj = commonLib.getVenueObj(accessObj);
        const seminarObj = commonLib.getSeminarObj(accessObj);
        const attendeeObj = commonLib.getSeminarAttendee(accessObj);

        Services.getDeviceType((deviceType) => {
            commonLib.setSessionStoarge("attendeeObj", JSON.stringify(attendeeObj));


            const subtenantId = seminarObj.seminarSubtenantId;
            const seminarId = seminarObj.seminarId;
            const attendeeEmailAddress = attendeeObj?.attendeeEmailAddress

            if (stage == "CHECKIN") {
                validateSeminarAttendee(subtenantId, seminarId, attendeeEmailAddress, selfCheckingCheckedIn);
            }
        })
    }

    return (
        <>
            <h3 className="text-center">Show your QR Code to Check-In.</h3>
                <div className="qrcodeConatiner">
                <QrReader
                    scanDelay="1000"
                    onResult={(result, error) => {
                        if (!!result) {
                            const qrlQuery = result.text;
                            const uId = commonLib.GetQueryValues(qrlQuery, 'UID');
                            const stage = commonLib.GetQueryValues(qrlQuery, 'Stage');
                            konsole.log("UID", uId, "Stage", stage);
                            setQRCode({
                                uId: uId,
                                stage: stage
                            })
                        }
                        if (!!error) {
                            // konsole.info(error);
                        }
                    }}
                    style={{ width: "100%" }}
                />
                </div>
        </>
    );
};

export default QrCode;
